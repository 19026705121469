import { DocumentNode, useQuery } from "@apollo/client";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import Button from "base-components/Button";
import IconText from "base-components/IconText";
import StatusMessage from "base-components/StatusMessage";
import ArticlePreview from "components/ArticlePreview";
import TabView from "components/TabView";
import { ARTICLE_LIST_QUERY, MOST_READ_ARTICLES } from "graphql/article";
import useDelayedLoading from "hooks/useDelayedLoading";
import { AnchorTarget, ClassNameArgument, FontAwesomeIconName } from "types";
import {
  ArticleList as ArticleListQuery,
  ArticleListVariables as ArticleListQueryVariables,
  MostReadArticles,
  MostReadArticlesVariables,
  SortOrder,
} from "types/graphql";
import styles from "./ArticleList.module.scss";

const ARTICLES_DISPLAY_LIMIT = 5;
const ARTICLES_DISPLAY_LIMIT_OFFSET = 10;

const MOST_READ_DAY_OPTIONS: Record<
  string,
  { value: number | null; label: any }
> = Object.freeze({
  "24 hours": {
    value: 1,
    label: defineMessage({
      defaultMessage: "24 hours",
    }),
  },
  "7 days": {
    value: 7,
    label: defineMessage({
      defaultMessage: "7 days",
    }),
  },
  "30 days": {
    value: 30,
    label: defineMessage({
      defaultMessage: "30 days",
    }),
  },
  Overall: {
    value: null,
    label: defineMessage({
      defaultMessage: "Overall",
    }),
  },
});

export type TemplateName = keyof typeof TEMPLATE;

type ArticleListPropsDefinition = {
  className?: ClassNameArgument;
  icon?: FontAwesomeIconName;
  query?: DocumentNode;
  limit?: ArticleListQueryVariables["limit"];
  orderBy?: ArticleListQueryVariables["orderBy"];
  title?: ReactNode;
  where?: ArticleListQueryVariables["where"];
  detailedPreview?: boolean;
  target?: AnchorTarget;
};

export type ArticleListProps<
  Template extends TemplateName | undefined = undefined
> = Template extends undefined
  ? ArticleListPropsDefinition & {
      template?: Template;
    }
  : Partial<ArticleListPropsDefinition> & {
      template: Template;
    };

const TEMPLATE: Readonly<
  Record<"newest" | "most-read" | "recommended", ArticleListPropsDefinition>
> = Object.freeze({
  newest: {
    icon: "bolt",
    orderBy: [{ publishedAt: SortOrder.desc }],
    title: (
      <FormattedMessage defaultMessage="Newest" description="Newest articles" />
    ),
  },
  recommended: {
    icon: "tags",
    orderBy: [{ publishedAt: SortOrder.desc }],
    title: (
      <FormattedMessage
        defaultMessage="Recommended"
        description="Recommended articles"
      />
    ),
  },
  "most-read": {
    icon: "fire-flame-curved",
    query: MOST_READ_ARTICLES,
    title: (
      <FormattedMessage
        defaultMessage="Most read"
        description="Most read articles"
      />
    ),
  },
});

const ArticleList = <Template extends TemplateName | undefined = undefined>({
  className,
  icon,
  limit,
  orderBy,
  title,
  where,
  detailedPreview,
  template: templateName,
  target,
}: ArticleListProps<Template>) => {
  const template = templateName && TEMPLATE[templateName];

  const articleOrderBy = orderBy ?? template?.orderBy;

  const [articlesLimit, setArticlesLimit] = useState(
    limit ?? ARTICLES_DISPLAY_LIMIT
  );

  const intl = useIntl();
  const [visitDayLimit, setVisitDayLimit] = useState<number | null>(1);

  const {
    data,
    error,
    loading: loadingArticles,
  } = useQuery<
    MostReadArticles & ArticleListQuery,
    MostReadArticlesVariables | ArticleListQueryVariables
  >(template?.query ? template.query : ARTICLE_LIST_QUERY, {
    variables: template?.query
      ? {
          limit: articlesLimit,
          visitDayLimit: visitDayLimit ?? undefined,
        }
      : {
          limit: articlesLimit,
          orderBy: articleOrderBy,
          where: {
            published: true,
            ...(where ?? template?.where ?? {}),
          },
        },
  });
  const loading = useDelayedLoading(loadingArticles, 300, true);

  return (
    <section className={classNames(styles.articles, className)}>
      {(title || template?.title) && (
        <IconText
          as="h3"
          className={styles.articlesTitle}
          icon={icon ?? template?.icon ?? "newspaper"}
        >
          {title || template?.title}
        </IconText>
      )}
      {templateName === "most-read" && (
        <TabView>
          {Object.keys(MOST_READ_DAY_OPTIONS).map((optionKey) => (
            <details key={`most-read-option-${optionKey}`}>
              <summary>
                <span
                  onClick={() =>
                    setVisitDayLimit(MOST_READ_DAY_OPTIONS[optionKey].value)
                  }
                >
                  {intl.formatMessage(MOST_READ_DAY_OPTIONS[optionKey].label)}
                </span>
              </summary>
            </details>
          ))}
        </TabView>
      )}
      <ul className={styles.articlesList}>
        {loading ? (
          Array(articlesLimit)
            .fill(null)
            .map((_, index) => (
              <li key={`article-sidebar-preview-${index}`}>
                <ArticlePreview
                  detailed={detailedPreview}
                  loading
                  target={target}
                />
              </li>
            ))
        ) : error ? (
          <StatusMessage type="error">
            <FormattedMessage
              defaultMessage="Failed to fetch articles"
              description="Sidebar articles fetch error"
            />
          </StatusMessage>
        ) : (
          (data?.mostReadArticles?.data ?? data?.articles?.data ?? []).map(
            (article) => (
              <li key={`article-sidebar-${article.id}`}>
                <ArticlePreview
                  detailed={detailedPreview}
                  article={article}
                  target={target}
                />
              </li>
            )
          )
        )}
      </ul>
      <Button
        backgroundColor="cool-gray-800"
        htmlType="button"
        className={classNames(styles.articlesLink, {
          [styles.articlesLinkCentered]: detailedPreview,
        })}
        onClick={() =>
          setArticlesLimit(articlesLimit + ARTICLES_DISPLAY_LIMIT_OFFSET)
        }
      >
        <FormattedMessage defaultMessage="Show more articles" />
      </Button>
      {articlesLimit > ARTICLES_DISPLAY_LIMIT && (
        <Button
          backgroundColor="cool-gray-600"
          onClick={() => {
            setArticlesLimit((currentLimit) =>
              Math.max(
                currentLimit - ARTICLES_DISPLAY_LIMIT_OFFSET,
                ARTICLES_DISPLAY_LIMIT
              )
            );
          }}
          htmlType="button"
        >
          <FormattedMessage defaultMessage="Show less" />
        </Button>
      )}
    </section>
  );
};

export default ArticleList;
