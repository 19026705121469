/* eslint-disable @next/next/no-img-element */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { LoadingIndicatorInline } from "base-components/LoadingIndicator";
import { getFilePath, themeColor } from "common/utils";
import { ClassNameArgument, FontAwesomeIconName, ThemeColor } from "types";
import styles from "./Thumbnail.module.scss";

export type ThumbnailProps<Loading extends boolean = false> = {
  aspectRatio?: `${number}/${number}`;
  className?: ClassNameArgument;
  icon?: FontAwesomeIconName;
  iconColor?: ThemeColor;
  loading?: Loading;
  src?: string | null;
} & (Loading extends true ? { alt?: string } : { alt: string });

const Thumbnail = <Loading extends boolean = false>({
  alt,
  aspectRatio = "16/9",
  className,
  icon = "image",
  iconColor = "cool-gray-200",
  loading,
  src,
}: ThumbnailProps<Loading>) => {
  const cn = classNames(styles.thumbnail, className);

  return !loading && src ? (
    <img
      alt={alt}
      className={cn}
      src={getFilePath(src)}
      style={{ aspectRatio }}
    />
  ) : (
    <div className={cn} style={{ aspectRatio }}>
      {loading ? (
        <LoadingIndicatorInline />
      ) : (
        <FontAwesomeIcon icon={icon} color={themeColor(iconColor)} />
      )}
    </div>
  );
};

export default Thumbnail;
