import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FunctionComponent } from "react";
import IntrinsicElementWrapper from "base-components/IntrinsicElementWrapper";
import { themeColor } from "common/utils";
import {
  ClassNameArgument,
  FontAwesomeIconName,
  IntrinsicElementName,
  Size,
  ThemeColor,
} from "types";
import styles from "./IconText.module.scss";

export type IconTextProps = {
  as?: IntrinsicElementName;
  className?: ClassNameArgument;
  icon: FontAwesomeIconName;
  iconColor?: ThemeColor;
  color?: ThemeColor;
  iconSize?: Size | "default";
};

const IconText: FunctionComponent<IconTextProps> = ({
  as = "p",
  children,
  className,
  color = "current",
  icon,
  iconColor = color,
  iconSize = "default",
}) => (
  <IntrinsicElementWrapper
    as={as}
    className={classNames(styles.iconText, className)}
    data-color={color}
  >
    <FontAwesomeIcon
      className={styles.iconTextIcon}
      icon={icon}
      color={themeColor(iconColor)}
      data-size={iconSize}
    />
    {children}
  </IntrinsicElementWrapper>
);

export default IconText;
