import { gql } from "@apollo/client";

const ARTICLE_FRAGMENT = gql`
  fragment ArticleParts on Article {
    id
    author {
      id
      name
      surname
      email
    }
    observers {
      id
    }
    userId
    title
    subtitle
    text
    mainImage
    mainImageFile {
      sourceTitle
      sourceURL
    }
    publishedAt
    createdAt
    tag
    tags
    subcategories {
      id
      title
    }
    categories {
      id
      title
    }
    uniqueVisitCount
    pageLoadCount
  }
`;

export const ARTICLES_QUERY = gql`
  query Articles(
    $where: ArticleWhereInput
    $offset: Int
    $limit: Int
    $orderBy: [ArticleOrderByInput!]
  ) {
    articles(where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
      data {
        ...ArticleParts
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const MOST_READ_ARTICLES = gql`
  query MostReadArticles($visitDayLimit: Int, $limit: Int!) {
    mostReadArticles(visitDayLimit: $visitDayLimit, limit: $limit) {
      data {
        ...ArticleParts
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_SELECT_QUERY = gql`
  query ArticleSelect(
    $where: ArticleWhereInput
    $offset: Int
    $limit: Int
    $orderBy: [ArticleOrderByInput!]
  ) {
    articles(where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
      data {
        id
        title
        mainImage
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const ARTICLE_QUERY = gql`
  query Article($where: ArticleWhereInput!) {
    article(where: $where) {
      ...ArticleParts
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_PAGE_QUERY = gql`
  query ArticlePage($where: ArticleWhereInput!) {
    article(where: $where) {
      ...ArticleParts
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_LIST_QUERY = gql`
  query ArticleList(
    $where: ArticleWhereInput
    $offset: Int
    $limit: Int
    $orderBy: [ArticleOrderByInput!]
  ) {
    articles(where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
      data {
        id
        title
        mainImage
        tag
        tags
        subtitle
        publishedAt
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const ARTICLE_CATEGORIES_QUERY = gql`
  query ArticleCategories(
    $where: ArticleCategoryWhereInput
    $offset: Int
    $limit: Int
  ) {
    articleCategories(where: $where, offset: $offset, limit: $limit) {
      data {
        id
        title
        default
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const ARTICLE_SUBCATEGORIES_QUERY = gql`
  query ArticleSubcategories(
    $where: ArticleSubcategoryWhereInput
    $offset: Int
    $limit: Int
  ) {
    articleSubcategories(where: $where, offset: $offset, limit: $limit) {
      data {
        id
        title
        default
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const ARTICLE_CATEGORY_QUERY = gql`
  query ArticleCategory($where: ArticleCategoryWhereInput!) {
    articleCategory(where: $where) {
      id
      title
    }
  }
`;

export const ARTICLE_OBSERVERS_QUERY = gql`
  query ArticleObservers($articleId: Int!) {
    articleObservers(articleId: $articleId) {
      id
      name
      surname
      email
    }
  }
`;

export const CREATE_ARTICLE_MUTATION = gql`
  mutation CreateArticle($data: ArticleInput!) {
    createArticle(data: $data) {
      id
    }
  }
`;

export const UPDATE_ARTICLE_MUTATION = gql`
  mutation UpdateArticle(
    $where: ArticleWhereUniqueInput!
    $data: ArticleInput!
  ) {
    updateArticle(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_ARTICLE_MUTATION = gql`
  mutation DeleteArticle($where: ArticleWhereUniqueInput!) {
    deleteArticle(where: $where) {
      id
    }
  }
`;

export const CONNECT_OBSERVER_MUTATION = gql`
  mutation AddObserver($articleId: Int!, $userId: Int!) {
    connectObserver(articleId: $articleId, userId: $userId) {
      id
    }
  }
`;

export const DISCONNECT_OBSERVER_MUTATION = gql`
  mutation RemoveObserver($articleId: Int!, $userId: Int!) {
    disconnectObserver(articleId: $articleId, userId: $userId) {
      id
    }
  }
`;

export const UPSERT_ARTICLE_VISIT_MUTATION = gql`
  mutation UpsertArticleVisit(
    $data: ArticleVisitInput!
    $incrementPageLoad: Boolean = true
  ) {
    upsertArticleVisit(data: $data, incrementPageLoad: $incrementPageLoad) {
      visitorId
    }
  }
`;
