import classNames from "classnames";
import { kebabCase } from "lodash";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import useUniqueId from "hooks/useUniqueId";
import { ClassNameArgument } from "types";
import styles from "./TabView.module.scss";

export type TabViewProps = {
  children?:
    | JSX.IntrinsicElements["details"]
    | JSX.IntrinsicElements["details"][];
  className?: ClassNameArgument;
  defaultTabIndex?: number;
  header?: ReactNode | ((currentTabIndex: number) => ReactNode);
  headerClassName?: ClassNameArgument;
  stickyHeader?: boolean;
  tabsDirection?: "row" | "column";
  tabStyle?: "default" | "browser";
  id?: string;
};

const TabView = ({
  children,
  className,
  defaultTabIndex = 0,
  header,
  headerClassName,
  stickyHeader = false,
  tabsDirection = "row",
  tabStyle = "default",
  id,
}: TabViewProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(defaultTabIndex);

  const tabTitles = useMemo(
    () =>
      [children]
        .flat()
        .map(
          (child) =>
            [(child as ReactElement)?.props.children]
              .flat()
              .find(({ type }) => type === "summary")?.props.children
        ),
    [children]
  );

  const tabContents = useMemo(
    () =>
      [children]
        .flat()
        .map((child) =>
          [(child as ReactElement)?.props.children]
            .flat()
            .filter(({ type }) => type !== "summary")
        ),
    [children]
  );

  const currentTabContent = useMemo(
    () => tabContents.find((_, index) => index === currentTabIndex),
    [tabContents, currentTabIndex]
  );

  const tabControlsId = useUniqueId();

  return (
    <section
      id={id}
      className={classNames(className, styles.tabView)}
      role="tablist"
    >
      <header
        className={classNames(headerClassName, styles.tabViewHeader)}
        data-sticky={stickyHeader}
        data-direction={tabsDirection}
        data-tab-style={tabStyle}
        id={tabControlsId}
      >
        {tabTitles.map((title, index) => (
          <button
            aria-controls={tabControlsId}
            aria-selected={index === currentTabIndex}
            key={`tabView-title-${kebabCase(title.toString())}-${index}`}
            className={styles.tabButton}
            data-current={index === currentTabIndex}
            data-style={tabStyle}
            onClick={() => setCurrentTabIndex(index)}
            type="button"
            role="tab"
          >
            {title}
          </button>
        ))}
        {typeof header === "function" ? header(currentTabIndex) : header}
      </header>
      <div className={styles.tabViewContent} role="tabpanel">
        {currentTabContent}
      </div>
    </section>
  );
};

export default TabView;
