import classNames from "classnames";
import { useMemo } from "react";
import IntrinsicElementWrapper from "base-components/IntrinsicElementWrapper";
import { limitString } from "common/utils";
import { ClassNameArgument, IntrinsicElementName } from "types";

export type LimitedStringProps = {
  as?: IntrinsicElementName;
  className?: ClassNameArgument;
  children?: string | null;
  limit: number;
  lead?: string;
};

const LimitedString = ({
  as = "span",
  className,
  children,
  limit,
  lead,
}: LimitedStringProps) => {
  const limitedString = useMemo(
    () => limitString(children ?? "", limit, lead),
    [children, lead, limit]
  );

  if (!as) return <>limitedString</>;

  return (
    <IntrinsicElementWrapper className={classNames(className)} as={as}>
      {limitedString}
    </IntrinsicElementWrapper>
  );
};

export default LimitedString;
