/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import { random, range } from "lodash";
import moment from "moment";
import { ReactNode } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import Skeleton from "react-loading-skeleton";
import IconText from "base-components/IconText";
import Tag from "base-components/Tag";
import Thumbnail from "base-components/Thumbnail/Thumbnail";
import { buildArticleURL } from "common/constants";
import AnchorLink from "components/AnchorLink";
import ArticleTags from "components/ArticleTags";
import { AnchorTarget, ClassNameArgument, ArticlePreviewPick } from "types";
import styles from "./ArticlePreview.module.scss";

export type ArticlePreviewProps = {
  children?: ReactNode;
  className?: ClassNameArgument;
  loading?: boolean;
  detailed?: boolean;
  article?: ArticlePreviewPick;
  target?: AnchorTarget;
};

const ArticlePreview = ({
  children,
  className,
  loading,
  article,
  detailed,
  target,
}: ArticlePreviewProps) => {
  if (detailed) {
    return (
      <article className={styles.articlePreview}>
        <figure className={styles.articlePreviewFigure}>
          <AnchorLink
            href={`/article/${article?.id}`}
            target={target}
            as={buildArticleURL(article)}
          >
            <Thumbnail
              alt={article?.title ?? ""}
              loading={loading}
              src={article?.mainImage}
            />
          </AnchorLink>
          {article?.tag && (
            <figcaption>
              <Tag
                className={styles.articlePreviewFigureTag}
                target={target}
                href={{
                  pathname: "/articles",
                  query: {
                    tag: article.tag,
                  },
                }}
              >
                {article.tag}
              </Tag>
            </figcaption>
          )}
        </figure>
        <section className={styles.articlePreviewContent}>
          {loading ? (
            <Skeleton count={1} height="1.5em" />
          ) : (
            <AnchorLink
              noUnderline
              href={`/article/${article?.id}`}
              target={target}
              as={buildArticleURL(article)}
            >
              <LinesEllipsis
                component="p"
                className={styles.articlePreviewContentTitle}
                text={article?.title ?? ""}
                maxLine={2}
              />
            </AnchorLink>
          )}
          {loading ? (
            <p className={styles.articlePreviewContentSubtitle} data-loading>
              {range(3).map((_, index) => (
                <Skeleton
                  key={`article-preview-${article?.id ?? ""}-${index}`}
                  count={1}
                  width={`${random(60, 90)}%`}
                />
              ))}
            </p>
          ) : (
            <LinesEllipsis
              className={styles.articlePreviewContentSubtitle}
              component="p"
              text={article?.subtitle ?? ""}
              maxLine={3}
            />
          )}
          <footer className={styles.articlePreviewContentMeta}>
            <time dateTime={article?.publishedAt}>
              <IconText icon="calendar-alt">
                {moment(article?.publishedAt).format("DD.MM.YYYY")}
              </IconText>
            </time>
            {article && (
              <ArticleTags target={target} article={article} max={4} />
            )}
          </footer>
        </section>
      </article>
    );
  }

  return (
    <article className={classNames(className)}>
      <AnchorLink
        className={styles.articleLink}
        href={loading ? "#" : `/article/${article?.id}`}
        target={target}
        as={buildArticleURL(article)}
      >
        <figure className={styles.articlePreviewFigure}>
          <Thumbnail
            alt={article?.title ?? ""}
            loading={loading}
            src={article?.mainImage}
          />
          <figcaption>
            {loading ? (
              <p className={styles.skeleton}>
                <Skeleton count={1} />
                <Skeleton count={1} width={`${random(40, 80)}%`} />
              </p>
            ) : (
              <LinesEllipsis
                component="p"
                maxLine={2}
                text={article?.title ?? ""}
              />
            )}
          </figcaption>
        </figure>
      </AnchorLink>
      {children}
    </article>
  );
};

export default ArticlePreview;
