import classNames from "classnames";
import IconText from "base-components/IconText";
import LimitedString from "base-components/LimitedString/LimitedString";
import AnchorLink from "components/AnchorLink";
import { AnchorTarget, ClassNameArgument } from "types";
import { ArticleParts } from "types/graphql";
import styles from "./ArticleTags.module.scss";

export type ArticleTagsProps = {
  className?: ClassNameArgument;
  article: Pick<ArticleParts, "id" | "tags">;
  max?: number;
  target?: AnchorTarget;
};

const ArticleTags = ({ className, article, max, target }: ArticleTagsProps) => {
  if (!article.tags) return <></>;

  return (
    <ul className={classNames(styles.tagList, className)}>
      {article.tags
        .filter(Boolean)
        .slice(0, max)
        .map((tag) => (
          <li key={`article-${article.id}-tag-${tag}`}>
            <AnchorLink
              noUnderline
              className={styles.tagListItemTag}
              target={target}
              href={{
                pathname: "/articles",
                query: { tag },
              }}
            >
              <IconText icon="tags">
                <LimitedString limit={32}>{tag}</LimitedString>
              </IconText>
            </AnchorLink>
          </li>
        ))}
    </ul>
  );
};

export default ArticleTags;
