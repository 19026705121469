import classNames from "classnames";
import { FunctionComponent } from "react";
import AnchorLink, { AnchorLinkProps } from "components/AnchorLink";
import { ClassNameArgument } from "types";
import styles from "./Tag.module.scss";

export type TagProps = {
  className?: ClassNameArgument;
  href: AnchorLinkProps["href"];
  target?: AnchorLinkProps["target"];
};

const Tag: FunctionComponent<TagProps> = ({
  children,
  className,
  href,
  target,
}) => {
  const cn = classNames(styles.tag, className);

  if (href) {
    return (
      <AnchorLink className={cn} href={href} target={target}>
        {children}
      </AnchorLink>
    );
  }

  return <span className={cn}>{children}</span>;
};

export default Tag;
